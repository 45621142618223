div.cardContainer {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;

    div.chatThreadContainer {
        width: 100%;
        height: 110px;
        margin: 0 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: auto;
        cursor: grab;

        &::-webkit-scrollbar {
            display: none;
        }

        .loadingContainer {
            width: 100%;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .thread {
            min-width: 80px;
            height: fit-content;
            margin: 0 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: grab;
            user-select: none;

            &.active {
                min-width: 100px;
                padding: 10px 10px 5px;
                border-radius: 10px;
                background-color: #d75f5f;

                .user_name {
                    color: white;
                }
            }

            .threadLogo {
                width: 60px;
                height: 60px;
                margin: auto;
                border-radius: 100%;
                user-select: none;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                pointer-events: none;

            }

            .user_name {
                width: fit-content;
                margin: 0 auto;
                color: grey;
                text-align: center;
                font-size: 12px;
                user-select: none;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            span.tag {
                position: absolute;
                top: 0;
                right: 0;
                background-color: red;
                color: white;
                padding: 5px 8px;
                border-radius: 100%;
                font-size: 10px;
                font-weight: 300;
            }
        }
    }

    .contentContainer {
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;



        div.initialContainer {
            width: 100%;
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2.title {
                color: grey;
            }

            p.description {
                color: grey;
            }
        }

    }
}