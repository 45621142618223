.card {



    @media (min-width: 1600px) {
        min-width: 20%;
    }

    @media (max-width : 575px) {
        margin: auto;
    }

    div.cardContent {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: white;



        div.imageContainer {
            width: 100%;
            display: flex;
            min-height: 150px;
            max-height: 17px;

            img {
                width: 100%;
            }
        }

        div.textContainer {
            padding: 10px 15px;


            div.header {
                display: flex;
                flex-direction: row;

                h3.name {
                    font-size: 14px;
                    margin: 0;
                    margin-right: auto;
                    display: '-webkit-box';
                    -webkit-box-orient: 'vertical';
                    -webkit-line-clamp: 1;
                    overflow: 'hidden';
                    text-overflow: 'ellipsis';
                }

                p.rating {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    margin-left: 10px;
                    color: black;

                    span {
                        display: flex;
                        align-items: center;
                        margin: 0 2px;
                    }
                }
            }

            p.address {
                margin: 5px 0;
                color: grey;
                font-weight: 400;
                font-size: 12px;

            }

            p.checkIns {
                margin: 0 0 5px 0;
                color: #2B28AE;
                font-weight: 400;
                font-size: 12px;
            }

            button.details {
                width: 100%;
                padding: 5px 0;
                margin: 15px 0 5px;
                border-radius: 50px;
                background-image: linear-gradient(to right, #B52929, #ED1B1B);
                border: none;
                color: white;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

}