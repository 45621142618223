.main {
    min-height: 100vh;
    margin: 0;
    display: flex;
    height: auto;
    background-color: white;

    .video {
        position: fixed;
        z-index: -2;
    }

    .translucentBack {
        width: 100%;
        min-height: 100vh;
        height: auto;
        display: flex;
        position: fixed;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.6);
    }


    .layout {
        min-height: 100vh;
        margin: 0;
        width: 100%;
        // background-image: url("./images/background.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        background-color: transparent;
        padding: 2% 0;
        display: flex;
        height: auto;





        .content {
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: inherit;
            width: 100%;
            height: inherit;


            img {
                position: relative;
                top: 0;
                user-select: none;
            }

            .form {
                margin: 50px 0;
                color: white;
                display: flex;
                flex-direction: column;
                user-select: none;
                height: -webkit-fill-available;


                h1 {
                    margin: 0;
                    text-align: center;
                    font: normal normal normal Gilroy-SemiBold;
                    font-size: 2rem;
                    font-weight: 600;
                    color: black;
                }

                p {
                    text-align: center;
                    margin-top: 20px;
                    opacity: 0.6;
                    color: gray;
                }

                .fields {
                    height: inherit;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin: auto;
                    width: 350px;

                    @media(max-width : 576px) {
                        width: 280px;
                    }

                    .inputFields {
                        margin: 12px 0;
                        padding: 0 30px;
                        width: 100%;
                        border-radius: 50px;
                        background-color: rgba(0, 0, 0, 0.8);
                        height: 70px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        @media(max-width : 576px) {
                            height: 60px;
                        }

                        input {
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                            padding: 0 30px 0 0;
                            color: white;
                            font-size: 16px;
                            border: none;
                            outline: none;
                        }


                        button {
                            border-radius: 100%;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: transparent;
                            cursor: pointer;
                            padding: 0;

                            svg {
                                font-size: 1.6rem;
                                color: white;
                                opacity: 0.6;
                            }
                        }



                    }

                    .checkboxField {
                        margin: 10px auto 10px 10px;

                        span {
                            color: black;

                        }

                    }
                }

                .submit {
                    width: 100%;
                    border-radius: 50px;
                    background-image: linear-gradient(to right, #B52929, #ED1B1B);
                    border: none;
                    color: white;
                    font-size: 1.6rem;
                    height: 120px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 350px;
                    margin: auto;

                    @media(max-width : 576px) {
                        height: 80px;
                        width: 280px;
                        font-size: 1.2rem;
                    }
                }

            }

            .switch {
                position: relative;
                bottom: 0;
                color: black;
                margin: 0;
                font: normal normal normal Gilroy-Medium;
                font-size: 20px;
                cursor: pointer;
                user-select: none;

                @media(max-width : 576px) {
                    font-size: 18px;
                }

            }
        }

    }
}