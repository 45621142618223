.recordContainer {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: right;

    .select {
        margin: 0 0 20px auto;
        width: 200px;
    }
}