div.statusContainer {
    display: flex;
    justify-content: right;

    button.status {
        cursor: pointer;
        padding: 10px 50px;
        border-radius: 15px;

        @media (max-width:768px) {
            padding: 6px 30px;
            font-size: 15px;
        }

        &.pending {
            background-color: #eed202
        }

        &.in-process {
            background-color: #339900
        }

        &.completed {
            background-color: #469cda
        }

        &.cancelled {
            background-color: #d87472
        }

        span {
            margin-right: 10px;
            font-size: 15px;
        }
    }
}

div.details {
    padding: 15px;

    @media (max-width:576px) {
        padding: 7px;
    }

    div.cardContainer {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        p.cardTitle {
            color: rgb(77, 76, 76);
            margin: 0 0 9px 5px;
            font-weight: 700;
            font-size: 19px;
        }

        div.card {
            width: 100%;
            height: fit-content;
            padding: 15px;
            border: 1px solid gray;
            border-radius: 20px;

            &.timeContainer {
                padding: 15px 30px;
                width: fit-content;

                h1.time {
                    font-size: 3rem;
                    font-weight: 700;
                    margin: 0;

                    @media (max-width:576px) {
                        font-size: 2rem;
                    }
                }
            }

            &.reasonContainer {
                background-color: #f1b2b0;
                border: none;

                p.cardTitle {
                    font-size: 17px;
                    color: #FF3333;
                    font-weight: 600;
                    margin: 0 0 7px 0;
                }

                p.description {
                    color: #FF3333;
                }

            }

            .userImage {
                width: 100%;
                height: 100%;
                aspect-ratio: 4/3;
                border-radius: 20px;
                max-height: 180px;
                margin: auto;
            }

            .userDetailsContainer {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;
                padding: 5px 15px;

                @media (max-width:576px) {
                    padding: 10px 0;
                }

                h3.name {
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0 0 6px 0;
                }

                p.info {
                    margin: 2px 0;
                    font-size: 14px;
                    color: gray;
                    display: flex;

                    span {
                        font-size: 17px;
                        margin: 0 5px 0;
                    }
                }
            }


            p.description {
                color: gray;
            }

            p.info {

                font-size: 17x;
                color: gray;

                span {
                    color: black;
                    font-weight: 500;
                }
            }
        }
    }
}