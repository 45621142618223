div.addOrUpdateSpot {
    width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    z-index: 2;
    max-height: 80vh;
    overflow: auto;

    @media (max-width: 576px) {
        width: 95%;
        padding: 12px;
    }

    div.headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1.title {
            font-size: 22px;
            margin: 0;
            margin-right: auto;

            @media (max-width: 576px) {
                font-size: 18px;
                margin: 0 auto 0 10px;

            }
        }

        span {
            cursor: pointer;
            font-size: 16px;
        }


    }

    hr.divider {
        margin: 15px 0 10px;
    }

    div.fieldsContainer {
        padding: 20px;
        display: flex;
        flex-direction: column;

        .field {
            margin: 10px 0;

            p.addImageLabel {
                margin: 5px 0;
                color: grey;
            }

            div.imagesContainer {
                display: inline-block;
                width: 100%;

                div.image {
                    width: 100px;
                    height: 100px;
                    border-radius: 10px;
                    margin: 5px 10px;
                    display: inline-block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }

        .container {
            display: flex;
            justify-content: center;

            &.multi {
                justify-content: left;
                width: -moz-fit-content;
                width: fit-content;
                margin: 5px 10px;
                display: inline-flex;
                align-items: center;

                div {
                    width: 100px;
                    height: 100px;

                    span {
                        width: 100px;

                        span.uploadIcon {
                            display: flex;
                            align-items: center;
                            font-size: 30px;
                            color: grey;
                            margin: 10px 0;
                        }
                    }
                }
            }

            div {
                width: fit-content;
                margin: 0;
                background-color: transparent;
                border-radius: 10px;
                overflow: hidden;

                span {
                    width: 150px;
                    display: flex;
                    flex-direction: column;

                    span.uploadIcon {
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        color: grey;
                        margin: 10px 0;
                    }
                }

                h3 {
                    color: grey;
                    opacity: 0.7;
                    font-size: 13px;
                }

            }
        }

        p.map {
            display: flex;
            align-items: center;
            margin: 5px 0;
            color: #551A8B;
            cursor: pointer;
            width: fit-content;

            @media (max-width : 1300px) {
                font-size: 13px;
            }

            svg {
                margin-right: 5px;
                font-size: 20px;

                @media (max-width : 1300px) {
                    font-size: 16px;
                }
            }
        }

        button.submit {
            width: fit-content;
            padding: 10px 90px;
            margin: 30px auto 5px;
            border-radius: 50px;
            background-image: linear-gradient(to right, #B52929, #ED1B1B);
            border: none;
            color: white;
            font-size: 17px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
        }
    }
}