.reviewsModal {
    width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    z-index: 2;
    max-height: 80vh;
    overflow: auto;

    @media (max-width: 576px) {
        width: 95%;
        padding: 12px;
    }

    div.headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1.title {
            font-size: 22px;
            margin: 0;
            margin-right: auto;

            @media (max-width: 576px) {
                font-size: 18px;
                margin: 0 auto 0 10px;

            }
        }

        span {
            cursor: pointer;
            font-size: 16px;
        }


    }

    hr.divider {
        margin: 15px 0 10px;
    }

}

.spotDetails {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;


    .containers {
        padding: 5px 0;

        &.detailsContainer {
            height: 35%;
            overflow: hidden;

            @media (max-width:991px) {
                height: fit-content;
                overflow: inherit;
            }

            .row {
                display: flex;
                flex-direction: row;
                height: 100%;

                @media (max-width:991px) {
                    flex-direction: column;
                    overflow: inherit;
                }


                .imageContainer {
                    height: 100%;
                    aspect-ratio: 16/9;

                    @media (max-width:991px) {
                        width: 100%;
                        max-width: 380px;
                        max-height: 200px;
                        margin: 0;
                        margin-right: auto;
                    }


                    @media (max-width:576px) {
                        max-width: 300px;
                        max-height: 200px;
                        margin: auto;
                    }


                    img.bannerImage {
                        width: 100%;
                        height: inherit;
                        overflow: hidden;
                        border-radius: 20px;
                        user-select: none;
                        aspect-ratio: 16/9;
                    }
                }

                .infoContainer {
                    width: -webkit-fill-available;
                    height: 100%;
                    padding: 0px 5px 0 20px;
                    display: flex;
                    flex-direction: column;
                    overflow: auto;

                    @media (max-width:991px) {
                        margin: 20px 0;
                        padding: 0;
                        overflow: initial;
                    }

                    div.titleContainer {
                        display: flex;
                        flex-direction: row;

                        @media (max-width:991px) {
                            flex-direction: column;
                        }

                        h1.title {
                            margin: 0;
                            margin-right: auto;

                            @media (max-width : 1300px) {
                                font-size: 22px;

                            }
                        }

                        p.checkIns {
                            margin: 10px 10px 5px;
                            color: #2B28AE;
                            font-weight: 400;
                            font-size: 17px;
                            white-space: nowrap;

                            @media (max-width : 1300px) {
                                font-size: 14px;
                                margin: 5px 10px 0;

                            }

                            @media (max-width : 991px) {
                                margin: 0;
                            }
                        }
                    }

                    div.ratingContainer {

                        margin: 0 0 5px;

                        div.rating {
                            margin: 0 0 5px 0;
                            color: grey;
                            font-weight: 400;
                            font-size: 14px;
                        }


                    }

                    div.tagsContainer {
                        margin: 10px 0;
                        display: inline-block;
                        flex-direction: row;

                        @media (max-width : 1300px) {
                            margin: 5px 0;
                        }

                        p.tags {
                            margin: 0;
                            color: grey;
                            display: inline-block;
                            width: fit-content;
                            font-weight: 600;


                            @media (max-width : 1300px) {
                                font-size: 13px;
                                font-weight: 400;
                            }

                            span {
                                margin: 0 10px;
                            }
                        }

                    }

                    p.map,
                    p.viewReviews {
                        display: flex;
                        align-items: center;
                        margin: 5px 0;
                        color: grey;
                        cursor: pointer;
                        width: fit-content;

                        @media (max-width : 1300px) {
                            font-size: 13px;
                        }

                        svg {
                            margin-right: 5px;
                            font-size: 20px;

                            @media (max-width : 1300px) {
                                font-size: 16px;
                            }
                        }
                    }

                    a.website {
                        display: flex;
                        align-items: center;
                        margin: 5px 0;
                        cursor: pointer;
                        width: fit-content;

                        @media (max-width : 1300px) {
                            font-size: 13px;
                        }

                        svg {
                            margin-right: 5px;
                            font-size: 20px;

                            @media (max-width : 1300px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        &.statsContainer {
            height: 65%;
            padding: 20px 0px;
            overflow: hidden;

            @media (max-width:991px) {
                // display: none;
                height: fit-content;
                overflow: inherit;
                padding: 0;

            }
            
            h3.header {
                margin-left: 10px;
                font-size: 23px;
                font-weight: 600;

                @media (max-width : 1300px) {
                    font-size: 18px;
                }

            }

            .stats {
                width: 100%;
                height: 100%;
                padding: 20px 10px;
                overflow: auto;

                .reviewStatsContainer {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    @media (max-width:991px) {
                        margin: 20px 0;
                    }

                    div.statsHeaderContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 0;
                        margin-bottom: 10px;

                        h2.statsTitle {
                            margin: 0;
                            font-size: 18px;

                            @media (max-width:1200px) {
                                font-size: 15px;
                            }
                        }

                        span {
                            margin-left: auto;
                        }
                    }



                    p.statsDescription {
                        color: gray;
                        text-align: center;
                    }

                }


            }
        }
    }

}

div.review {
    padding: 0 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @media(max-width: 991px) {
        margin: 20px 0;
    }

    div.headerContainer {
        width: 100%;
        display: flex;
        flex-direction: row;

        img.user_avatar {
            width: 50px;
            height: 50px;
            border-radius: 100%;
        }

        div.info {
            width: 100%;
            padding: 0 10px;
            display: flex;
            flex-direction: column;

            div.header {
                display: flex;
                flex-direction: row;
                align-items: center;

                h3.name {
                    margin: 0;
                    font-size: 15px;

                    @media (max-width : 1300px) {
                        font-size: 13px;
                    }
                }

                p.date {
                    margin: 0;
                    margin-left: 12px;
                    font-size: 13px;
                    color: grey;

                    @media (max-width : 1300px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    p.description {
        margin: 8px 0;
        color: grey;

        @media (max-width : 1300px) {
            font-size: 13px;
        }
    }
}