.modalContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 0;

    .outside {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 2;
        background-color: rgb(0, 0, 0, 0.8);
        top: 0;
    }
}