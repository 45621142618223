.passwordPopUp {
    width: fit-content;
    min-width: 500px;
    height: fit-content;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    z-index: 2;
    
    div.headerContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1.title {
            font-size: 20px;
            margin: 0;
            margin-right: auto;
        }

        span {
            cursor: pointer;
            font-size: 16px;
        }


    }

    hr.divider {
        margin: 15px 0 10px;
    }

    div.fieldsContainer {
        display: flex;
        flex-direction: column;

        .field {
            margin: 10px 0;

        }

        button.submit {
            width: fit-content;
            padding: 5px 60px;
            margin: 30px auto 5px;
            border-radius: 50px;
            background-image: linear-gradient(to right, #B52929, #ED1B1B);
            border: none;
            color: white;
            font-size: 17px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}