.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 5px;

    .optionsContainer {
        display: flex;
        flex-direction: row;
        padding: 0 0 10px;

        div.backContainer {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: gray;
            margin-right: auto;

            span.icon {
                display: flex;
                align-self: center;

                svg {
                    font-size: 1.5rem;

                    @media (max-width:768px) {
                        font-size: 1.2rem
                    }
                }
            }

            h3.backText {
                margin: 0 0 0 5px;
                font-size: 17px;
                color: gray;

                @media (max-width:768px) {
                    font-size: 15px
                }
            }
        }

        div.allButtonContainer {
            display: flex;
            flex-direction: row;
            margin-left: auto;
        }

        div.addButtonContainer {
            display: flex;
            justify-content: right;
            margin: 0 0 0 15px;

            button.button {
                cursor: pointer;
                padding: 10px 30px;
                border-radius: 15px;

                &.addNew {
                    background-image: linear-gradient(to right, #B52929, #ED1B1B);

                }

                &.delete {
                    padding: 10px 40px;
                    background-color: #d87472;

                }

                &.edit {
                    padding: 10px 40px;
                    background-color: #3aa83a;
                }

                @media (max-width:768px) {
                    padding: 10px 22px;
                    font-size: 12px;
                }

                span {
                    margin-right: 10px;
                    font-size: 15px;

                    @media (max-width:768px) {
                        margin-right: 6px;
                        font-size: 12px;
                    }
                }
            }
        }

    }

    .contentContainer {
        width: 100%;
        height: inherit;
        display: flex;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        flex-direction: column;
        box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
        overflow: auto;

    }
}