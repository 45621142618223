.profile {
    h1.title {
        margin: 0;
    }

    .fieldsContainer {
        width: 400px;
        height: fit-content;
        margin: 30px auto;

        @media (max-width : 991px) {
            width: 350px;

        }

        @media (max-width : 575px) {
            width: 100%;

        }

        .imageContainer {
            display: flex;
            justify-content: center;

            div {
                width: fit-content;
                margin: 0;
                background-color: transparent;
                border-radius: 10px;
                overflow: hidden;

                span {
                    width: 150px;
                    display: flex;
                    flex-direction: column;

                    span.uploadIcon {
                        display: flex;
                        align-items: center;
                        font-size: 30px;
                        color: grey;
                        margin: 10px 0;
                    }
                }

                h3 {
                    color: grey;
                    opacity: 0.7;
                    font-size: 13px;
                }

            }
        }

        .field {
            margin: 10px 0;

            .icon {
                margin: 0 8px 0 3px;
            }


        }

        div.mapContainer {
            width: 100%;
            height: 250px;
            margin: 10px 0;
            background-color: lightgrey;

            border-radius: 20px;
        }

        button.submit {
            width: fit-content;
            padding: 10px 120px;
            margin: 50px auto 5px;
            border-radius: 50px;
            background-image: linear-gradient(to right, #B52929, #ED1B1B);
            border: none;
            color: white;
            font-size: 17px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}